import inFo from "../utils/inFo.js";
//图片下载
export function downloadBase(baseData, name) {
  let arr = baseData.split(","),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let myBlob = new Blob([u8arr], { type: mime });
  let url = URL.createObjectURL(myBlob);
  let a = document.createElement("a");
  a.setAttribute("href", url);
  a.setAttribute("download", name);
  a.setAttribute("target", "_blank");
  let clickEvent = document.createEvent("MouseEvents");
  clickEvent.initEvent("click", true, true);
  a.dispatchEvent(clickEvent);
}

/**
 * base64图片转文件
 * @param urlData  图片数据
 * @param fileName 图片名称
 * @param typeName 图片类型
 */
export const base64ToFile = (urlData, fileName, typeName) => {
  const arr = urlData.split(',')
  const mime = arr[0].match(/:(.*?);/)[1]
  const bytes = atob(arr[1]) // 解码base64
  let n = bytes.length
  const ia = new Uint8Array(n)
  while (n--) {
    ia[n] = bytes.charCodeAt(n)
  }
  return new File([ia], fileName, {
    type: typeName || mime
  })
}
//关闭loading
export function getJsonDataRes(res) {
  try{
    console.log(res);
    inFo.emit('getJsonDataResp', res);
  }catch (e) {
    console.log(e);
  }
}
